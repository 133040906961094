import { useState } from "react";

import { Typography } from "@material-tailwind/react";

import DrawerMenu from "../components/Menus/DrawerMenu";
import ProfileMenu from "../components/Menus/ProfileMenu";

const Layout = ({ children }) => {
  const [drawerMenuState, setDrawerMenuState] = useState(false);

  return (
    <div className="p-5 w-full flex items-center justify-center">
      <div className="flex flex-col flex-wrap w-full">
        <div className="flex flex-wrap gap-4 items-center md:justify-between justify-center mb-4">
          <div className="flex flex-col gap-2 items-center justify-center md:flex-row">
            <DrawerMenu
              drawState={drawerMenuState}
              setDrawState={setDrawerMenuState}
            />
            <img
              className="h-[40px] w-[40px]"
              src="https://hub.fl1digital.com/wp-content/uploads/2018/04/fl1-logo.png"
              alt="nature image"
            />
            <Typography
              variant="h1"
              color="gray"
              className="flex justify-center gap-1 text-2xl font-bold"
            >
              Dashboard
            </Typography>
          </div>
          <div className="flex flex-col gap-2 items-center justify-center md:flex-row">
            <Typography
              variant="h1"
              color="gray"
              className="flex justify-center gap-1 text-xl font-bold"
            >
              Jason Sammon
            </Typography>
            <ProfileMenu />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Layout;
