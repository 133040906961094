import { Card, CardBody, Typography } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { formatCurrency } from "../../utils/numbers";

const ServicesBreakdownCard = ({ prospects }) => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const calculateStats = () => {
      // createa a set of services with, totalling value, quantity and percentage
      // of total value
      const services = new Map();
      prospects.forEach((prospect) => {
        if (services.has(prospect.serviceType)) {
          const service = services.get(prospect.serviceType);
          service.value += parseFloat(prospect.estimated_value);
          service.qty += 1;
          services.set(prospect.serviceType, service);
        } else {
          services.set(prospect.serviceType, {
            value: parseFloat(prospect.estimated_value),
            qty: 1,
          });
        }
      });

      const totalValue = Array.from(services.values()).reduce(
        (acc, service) => acc + service.value,
        0
      );

      const servicesWithPercentage = Array.from(services.entries()).map(
        ([service, { value, qty }]) => ({
          service,
          value,
          qty,
          percentage: ((value / totalValue) * 100).toFixed(0),
        })
      );

      servicesWithPercentage.sort((a, b) => b.value - a.value);
      setStats(servicesWithPercentage);
    };
    calculateStats();
  }, [prospects]);

  return (
    <Card color="gray" variant="gradient" className="w-full max-w-[30rem] p-8">
      <CardBody className="p-0 min-h-52">
        <div className="flex gap-4">
          <div className="flex items-center min-w-[250px]">
            <Typography className="font-normal">Service</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Value</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Qty</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">%</Typography>
          </div>
        </div>
        {stats &&
          stats.map((service) => (
            <div key={service.service} className="flex gap-4">
              <div className="flex min-w-[250px]">
                <Typography className="font-normal">
                  {service.service}
                </Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  £{formatCurrency(service.value)}
                </Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">{service.qty}</Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  {service.percentage}%
                </Typography>
              </div>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};
export default ServicesBreakdownCard;
