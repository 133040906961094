import api from "../data/api";
import { dateFormatWordPress } from "../utils/dates";

class ProspectService {
  static async getProspects(startDate, endDate) {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_HUB_URL}prospects?from_date=${startDate}&to_date=${endDate}`
      );

      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
  static async getProspect(id) {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_HUB_URL}prospects/${id}`
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
  static async createProspect(
    title,
    authorId,
    client,
    source,
    status,
    account_manager,
    service_type,
    estimated_value
  ) {
    try {
      const newProspect = new FormData();
      newProspect.append("title", title);
      newProspect.append("author", authorId);
      newProspect.append("client", client);
      newProspect.append("source", source);
      newProspect.append("status", status);
      newProspect.append("account_manager", account_manager);
      newProspect.append("service_type", service_type);
      newProspect.append("estimated_value", estimated_value);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const res = await api.post(
        `${process.env.REACT_APP_HUB_URL}prospects`,
        newProspect,
        config
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
  static async validateNewProspect(data) {
    const errors = [];

    if (!data.title) {
      errors.push({
        id: "title",
        message: "Please enter a valid project title",
      });
    }
    if (!data.authorId) {
      errors.push({
        id: "authorId",
        message: "Please enter a valid author",
      });
    }
    if (!data.client) {
      errors.push({
        id: "client",
        message: "Please enter a valid client",
      });
    }
    if (!data.service_type) {
      errors.push({
        id: "service_type",
        message: "Please enter a valid Service Type",
      });
    }
    return errors.length > 0 ? errors : [];
  }
  static async createProspectAction(prospect, action, date, notes) {
    console.log(prospect, action, date, notes);

    const actionDate = dateFormatWordPress(date);
    console.log(actionDate);

    try {
      const newAction = new FormData();
      newAction.append("prospect", prospect);
      newAction.append("action", action);
      newAction.append("date", actionDate);
      newAction.append("notes", notes);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const res = await api.post(
        `${process.env.REACT_APP_HUB_URL}prospects/action`,
        newAction,
        config
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default ProspectService;
