import { Card, CardBody, Typography } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { formatCurrency } from "../../utils/numbers";

const SourcesBreakdownCard = ({ prospects }) => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const calculateStats = () => {
      // createa a set of services with, totalling value, quantity and percentage
      // of total value
      const sources = new Map();
      prospects.forEach((prospect) => {
        // if prospect.source starts with Referal, change it to Referral
        if (prospect.source.startsWith("Referral")) {
          prospect.source = "Referral";
        }

        if (sources.has(prospect.source)) {
          const source = sources.get(prospect.source);
          source.value += parseFloat(prospect.estimated_value);
          source.qty += 1;
          sources.set(prospect.source, source);
        } else {
          sources.set(prospect.source, {
            value: parseFloat(prospect.estimated_value),
            qty: 1,
          });
        }
      });

      const totalValue = Array.from(sources.values()).reduce(
        (acc, source) => acc + source.value,
        0
      );

      const sourcesWithPercentage = Array.from(sources.entries()).map(
        ([source, { value, qty }]) => ({
          source,
          value,
          qty,
          percentage: ((value / totalValue) * 100).toFixed(0),
        })
      );

      // re-sort the array by value, highest first
      sourcesWithPercentage.sort((a, b) => b.value - a.value);

      setStats(sourcesWithPercentage);
    };
    calculateStats();
  }, [prospects]);

  return (
    <Card color="gray" variant="gradient" className="w-full max-w-[30rem] p-8">
      <CardBody className="p-0 min-h-52">
        <div className="flex gap-4">
          <div className="flex items-center min-w-[250px]">
            <Typography className="font-normal">Source</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Value</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Qty</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">%</Typography>
          </div>
        </div>
        {stats &&
          stats.map((source) => (
            <div key={source.source} className="flex gap-4">
              <div className="flex min-w-[250px]">
                <Typography className="font-normal">{source.source}</Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  £{formatCurrency(source.value)}
                </Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">{source.qty}</Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  {source.percentage}%
                </Typography>
              </div>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};
export default SourcesBreakdownCard;
