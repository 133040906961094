import { useState } from "react";
import useProspects from "../../hooks/useProspects";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Textarea,
  Select,
  Option,
} from "@material-tailwind/react";

import statusList from "../../config/statusOptions";

const defaultData = {
  followUpDate: new Date().toISOString().split("T")[0],
  followUpType: 5,
  followUpNotes: "",
};

const QuickNoteDialog = ({ open, setOpen, prospect }) => {
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(false);
  const [, , , , createProspectAction] = useProspects();

  const onSaveNote = async () => {
    // Flag up an error then clear it after 3 seconds
    if (!data.followUpNotes) {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
      return;
    }

    const newAction = {
      prospect: prospect.id,
      action: data.followUpType,
      date: new Date(data.followUpDate),
      notes: data.followUpNotes,
    };

    console.log(newAction);
    const res = await createProspectAction(newAction);

    // Clear the data for the next Note
    handleOpen();
    setData(defaultData);
  };

  const onFieldChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value,
    });
  };

  const handleOpen = () => setOpen(!open);
  return (
    <>
      <Dialog open={open} size="xl" handler={handleOpen}>
        <div className="flex items-center justify-between">
          <DialogHeader className="flex flex-col items-start">
            <Typography className="mb-1" variant="h4">
              Add a Quick Note
            </Typography>
          </DialogHeader>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5"
            onClick={handleOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody>
          <Typography variant="h6" color="blue-gray" className="mt-3">
            New Status
          </Typography>
          <Select
            id="followUpType"
            size="lg"
            label="What needs to happen next?"
            value={data.followUpType}
            onChange={(value) =>
              onFieldChange({ target: { id: "followUpType", value: value } })
            }
          >
            {statusList.map((status, index) => (
              <Option key={index + 1} value={index + 1}>
                {status}
              </Option>
            ))}
          </Select>

          <Typography variant="h6" color="blue-gray" className="mt-3">
            Notes
          </Typography>
          <Textarea
            error={error}
            id="followUpNotes"
            variant="outlined"
            label="Follow up task notes"
            rows={14}
            onChange={onFieldChange}
            value={data.followUpNotes}
          />
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="gradient" color="gray" onClick={onSaveNote}>
            Create Note
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default QuickNoteDialog;
