import { Card, CardBody, Typography } from "@material-tailwind/react";

import { useState, useEffect } from "react";

const DashboardTotalsCard = ({ prospects, totals }) => {
  const [stats, setStats] = useState({});

  const TextIf = (condition, ifZeroText, ifOneText, ifMultipleText) => {
    switch (condition) {
      case 0:
        return ifZeroText;
      case 1:
        return ifOneText;
      default:
        return ifMultipleText;
    }
  };

  useEffect(() => {
    const calculateStats = () => {
      let totalSalesValue = 0;
      let totalSales = 0;
      let averageSale = 0;
      let totalConversionPC = 0;
      let totalConversionAvDays = 0;

      prospects &&
        prospects.forEach((prospect) => {
          if (prospect.isConverted === "1") {
            totalSalesValue += parseFloat(prospect.estimated_value);
            totalSales += 1;
          }
        });

      // Calculate average sale
      averageSale = totalSalesValue ? totalSalesValue / totalSales : 0;

      // Rounds to 2 decimal places
      averageSale = averageSale.toFixed(2);

      // rounds to 2 decimal places
      totalSalesValue = totalSalesValue.toFixed(2);

      setStats({
        totalSalesValue,
        totalSales,
        averageSale,
        totalConversionPC,
        totalConversionAvDays,
      });
    };

    calculateStats();
  }, [prospects]);

  return (
    <Card color="red" variant="gradient" className="w-full max-w-[20rem] p-8">
      <CardBody className="p-0">
        <Typography
          variant="h1"
          color="white"
          className="my-2 flex justify-center gap-1 text-3xl font-normal"
        >
          <span className="text-2xl">
            {TextIf(stats.totalSalesValue, "", "£", "£")}
          </span>
          {stats.totalSalesValue}{" "}
        </Typography>
        <li className="flex items-center">
          <div className="min-w-[150px]">This Month:</div>
          <Typography className="font-normal">
            {TextIf(totals?.thisMonth?.total, "", "£", "£")}
            {totals?.thisMonth?.total || 0}
          </Typography>
        </li>

        <li className="flex items-center">
          <div className="min-w-[150px]">This Week:</div>
          <Typography className="font-normal">
            {TextIf(totals?.thisWeek?.total, "", "£", "£")}
            {totals?.thisWeek?.total || 0}
          </Typography>
        </li>
        <ul className="flex flex-col">
          <li className="flex items-center">
            <div className="min-w-[150px]">Sales:</div>
            <Typography className="font-normal">
              {stats.totalSales || 0}{" "}
            </Typography>
          </li>
          <li className="flex items-center">
            <div className="min-w-[150px]">Average:</div>
            <Typography className="font-normal">
              {TextIf(stats.averageSale, "", "£", "£")}
              {stats.averageSale || 0}
            </Typography>
          </li>
          <li className="flex items-center">
            <div className="min-w-[150px]">Conversion %:</div>
            <Typography className="font-normal">
              {stats.totalSales || 0}%
            </Typography>
          </li>
          <li className="flex items-center">
            <div className="min-w-[150px]">Conversion Time:</div>
            <Typography className="font-normal">
              {stats.totalConversionAvDays}{" "}
              {TextIf(stats.totalConversionAvDays, "", "day", "days")}
            </Typography>
          </li>
        </ul>
      </CardBody>
    </Card>
  );
};
export default DashboardTotalsCard;
