import { useState } from "react";
import { Alert, Button } from "@material-tailwind/react";

const AlertDismissible = ({ children }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      {!open && (
        <Button className="absolute" onClick={() => setOpen(true)}>
          Show Alert
        </Button>
      )}
      <Alert
        open={open}
        onClose={() => setOpen(false)}
        placement="top"
        color="red"
        className="mb-4"
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
      >
        {children}
      </Alert>
    </>
  );
};

export default AlertDismissible;
