import { useEffect, useState } from "react";
import { Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; //

import Layout from "../components/Layout";

import Firebase from "../services/Firebase";

const BusinessCards = () => {
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Firebase.GetImages("images");
      console.log(urls);
      setImages(urls);
    };

    fetchImages();
  }, []);

  return (
    <Layout>
      <div className="gap-4 items-center justify-center mb-4">
        <Typography className="font-normal text-center">
          Business Cards Scanned
        </Typography>
        <Carousel
          showArrows={true}
          dynamicHeight={true}
          showThumbs={true}
          width={"600px"}
        >
          {images.map((image, index) => (
            <div>
              <img key={index} src={image} alt="business card" />
            </div>
          ))}
        </Carousel>
      </div>
    </Layout>
  );
};

export default BusinessCards;
