import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MenuItem, Button, Typography } from "@material-tailwind/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { jsPDF } from "jspdf"; //or use your library of choice here
import autoTable from "jspdf-autotable";

const DataTable = ({
  columns,
  data,
  newItemName,
  onNewItem,
  onOpenItem,
  onRightClickItem,
  menuActions,
  onRenderDetailPanel,
}) => {
  const handleExportRows = (rows) => {
    const doc = new jsPDF();
    const tableData = rows.map((row) => {
      const rowValues = columns.map((c) => row.original[c.accessorKey]);
      return rowValues;
    });
    const tableHeaders = columns.map((c) => c.header);

    autoTable(doc, {
      head: [tableHeaders],
      body: tableData,
    });

    doc.save("mrt-pdf-example.pdf");
  };

  const renderMenuItems = (row, closeMenu) => {
    return menuActions.map((action) => (
      <MenuItem
        key={action.label}
        onClick={() => {
          action.onClick(row);
          closeMenu();
        }}
        className="flex items-center gap-2"
      >
        {action.icon}
        <Typography variant="small" className="font-medium">
          {action.label}
        </Typography>
      </MenuItem>
    ));
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableClickToCopy: false,
    enableRowSelection: true,
    columnFilterDisplayMode: "popover",
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "bottom",
    enableRowActions: true,
    positionActionsColumn: "end",
    muiSelectCheckboxProps: {
      color: "primary", //makes all checkboxes use the secondary color
    },
    renderDetailPanel: ({ row }) => {
      if (onRenderDetailPanel) return onRenderDetailPanel(row);
    },
    renderRowActionMenuItems: ({ row, closeMenu }) =>
      renderMenuItems(row, closeMenu),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: (event) => {
        onOpenItem(row.id, row.original);
      },
      onContextMenu: (event) => {
        if (onRightClickItem) onRightClickItem(event, row);
      },
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="flex gap-4 items-center flex-wrap">
        <Button
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={onNewItem}
          startIcon={<FileDownloadIcon />}
        >
          New {newItemName}
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          //only export selected rows
          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </div>
    ),
  });

  return <MaterialReactTable table={table} />;
};

export default DataTable;
