import { useState } from "react";
import {
  Input,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

const AutoCompleteInputControl = ({
  selectionData,
  selectedItem,
  setSelectedItem,
}) => {
  const [data, setData] = useState(selectionData);
  const [filteredData, setFilteredData] = useState(selectionData);
  const [open, setOpen] = useState(false);

  const onSelectItem = (item) => {
    console.log(item);
    setOpen(false);
    setSelectedItem(item);
    setFilteredData(data);
  };

  const onSearchTextChange = (text) => {
    const filteredData = data.filter((item) => {
      return item.title.toLowerCase().includes(text.toLowerCase());
    });
    setFilteredData(filteredData);
  };

  return (
    <div className="relative flex w-full max-w-[24rem]">
      <div className="relative flex w-full max-w-[24rem]">
        <Input
          type="text"
          label=""
          className="pr-20"
          onClick={() => setOpen(true)}
          containerProps={{
            className: "min-w-0",
          }}
          value={selectedItem.title}
        />
        <Menu
          open={open}
          handler={setOpen}
          placement="bottom-end"
          dismiss={() => {
            setOpen(false);
          }}
        >
          <MenuHandler>
            <Button size="sm" className="!absolute right-1 top-1 rounded">
              ...
            </Button>
          </MenuHandler>
          <MenuList className="max-h-[20rem]">
            <Input
              autoFocus={true}
              type="text"
              id="search"
              label="start typing to search"
              className="pr-20"
              onChange={(e) => onSearchTextChange(e.target.value)}
              containerProps={{
                className: "min-w-[355px]",
              }}
            />
            {filteredData.map(({ id, title }, index) => {
              return (
                <MenuItem
                  key={id}
                  value={title}
                  className="flex items-center gap-2 min-w-[355px]"
                  onClick={() => onSelectItem({ id, title: title })}
                >
                  {title}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default AutoCompleteInputControl;
