const sourceList = [
  "Existing Customer",
  "Referral",
  "Google Ads/SEO",
  "Social Media Post",
  "Met at an event",
  "Email Campaign",
  "Something else",
  "Video Ask",
  "Midland Expo",
];

export default sourceList;
