import { useEffect, useState } from "react";
import { Typography, Checkbox, Input, Radio } from "@material-tailwind/react";

import ContactSourceSelect from "./ContactSourceSelect";
import ClientAutoCompleteInputControl from "./ClientAutoCompleteInputControl";

const ClientSelectionControl = ({
  selectedClient,
  setSelectedClient,
  errors,
}) => {
  const [newContact, setNewContact] = useState(true);

  const onFieldChange = (e) => {
    setSelectedClient({
      ...selectedClient,
      [e.target.id]: e.target.value,
    });
  };

  const onSetNewContact = (value) => {
    setNewContact(value);
    if (!value) {
      setSelectedClient({
        ...selectedClient,
        source: "",
        newsletter: false,
      });
    }
  };

  return (
    <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
      <div className="mb-1 flex flex-col gap-6">
        <div className="flex gap-10">
          <Radio
            name="type"
            label="New Contact"
            checked={newContact}
            onClick={() => onSetNewContact(true)}
          />
          <Radio
            name="type"
            label="Existing Client"
            onClick={() => onSetNewContact(false)}
          />
        </div>
        {newContact ? (
          <>
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Contact Name
            </Typography>
            <Input
              error={errors?.find((error) => error.id === "contact")}
              size="lg"
              id="contact"
              placeholder="John Smith"
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              onChange={onFieldChange}
              value={selectedClient.contact}
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Email
            </Typography>
            <Input
              error={errors?.find((error) => error.id === "email")}
              size="lg"
              id="email"
              placeholder="name@mail.com"
              onChange={onFieldChange}
              value={selectedClient.email}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Phone Number
            </Typography>
            <Input
              error={errors?.find((error) => error.id === "phone")}
              size="lg"
              id="phone"
              placeholder="01727 739812"
              onChange={onFieldChange}
              value={selectedClient.phone}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Company Name
            </Typography>
            <Input
              error={errors?.find((error) => error.id === "company")}
              size="lg"
              id="company"
              placeholder="Company Name"
              onChange={onFieldChange}
              value={selectedClient.company}
              className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              labelProps={{
                className: "before:content-none after:content-none",
              }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              How did you hear about us?
            </Typography>
            <ContactSourceSelect
              selection={selectedClient.source}
              onSelection={onFieldChange}
              hasError={errors?.find((error) => error.id === "source")}
            />
            <Checkbox
              id="newsletter"
              checked={selectedClient.newsletter}
              onChange={(e) =>
                setSelectedClient({
                  ...selectedClient,
                  newsletter: e.target.checked,
                })
              }
              label={
                <Typography
                  variant="small"
                  color="gray"
                  className="flex items-center font-normal"
                >
                  Sign up for our newsletter and latest news and updates.
                </Typography>
              }
              containerProps={{ className: "-ml-2.5" }}
            />
          </>
        ) : (
          <>
            <Typography variant="h6" color="blue-gray" className="-mb-3">
              Select Client
            </Typography>
            <ClientAutoCompleteInputControl
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          </>
        )}
      </div>
    </form>
  );
};

export default ClientSelectionControl;
