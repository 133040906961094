const prospectActionOptions = {
  OptionNewLead: 1,
  OptionWaitingToQuote: 2,
  OptionInProgress: 3,
  OptionPartQuoted: 4,
  OptionQuoted: 5,
  OptionFollowedUp: 6,
  OptionConverted: 7,
  OptionNotNeeded: 8,
  OptionGhosted: 9,
  OptionDidntWin: 10,
  OptionOther: 11,
}

export default prospectActionOptions;