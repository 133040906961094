import axios from "axios";

class AuthService {
  static async Login(username, password) {
    const res = await axios.post(`${process.env.REACT_APP_AUTH_URL}`, {
      username,
      password,
    });

    const { data } = res.data;
    if (res.status === 200) {
      localStorage.setItem("token", data.token);
    }
    return data.token;
  }

  static async LoginAnonymous() {
    // Get the Anon account from React .env variables
    const username = process.env.REACT_APP_ANON_USERNAME;
    const password = process.env.REACT_APP_ANON_PASSWORD;

    return this.Login(username, password);
  }

  static Logout() {
    localStorage.removeItem("token");
    return true;
  }

  static IsLoggedIn() {
    return localStorage.getItem("token") ? true : false;
  }

  static GetUserId() {

    // Get the token from local storage
    const token = localStorage.getItem("token");

    // Decode the token
    const decoded = JSON.parse(atob(token.split(".")[1]));

    // Return the user id
    return decoded.id;
  }

  static GetToken() {
    return localStorage.getItem("token") || "";
  }
}

export default AuthService;
