const isValidEmail = (email) => {
  const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  return isValid;
};

const isValidPhone = (phone) => {
  const isValid = /^((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/.test(phone);
  return isValid;
};

const isValidName = (name) => {
  return /^[a-zA-Z\s]+$/.test(name);
};

const isValidNotes = (notes) => {
  return /^[a-zA-Z\s]+$/.test(notes);
};

export { isValidEmail, isValidPhone, isValidName, isValidNotes };
