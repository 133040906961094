import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

import firebaseConfig from "../config/firebase";

firebase.initializeApp(firebaseConfig);

class Firebase {
  static async GetImages(folder) {
    const imageRef = await firebase.storage().ref().child(folder).listAll();

    const urls = await Promise.all(
      imageRef.items.map(async (item) => {
        return await item.getDownloadURL();
      })
    );

    return urls;
  }
}

export default Firebase;
