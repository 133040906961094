import api from "../data/api";

import {isValidEmail, isValidPhone, isValidName} from "../utils/validation";

class ClientService {
 static async getClients() {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_HUB_URL}clients`
      );
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
  static async getClient(id) {
    try {
      const res = await api.get(`${process.env.REACT_APP_HUB_URL}clients/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  }
  static async createClient(
    company,
    authorId,
    contact,
    email,
    phone
  ) {
    
    try {
      const newClient = new FormData();
      newClient.append("title", company);
      newClient.append("author", authorId);
      newClient.append("contact", contact);
      newClient.append("email", email);
      newClient.append("phone", phone);

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      const res = await api.post(
        `${process.env.REACT_APP_HUB_URL}clients`,
        newClient,
        config
      );
      return res.data;
    } catch (error) {
      throw { message: error.response.data.message };
    }
  }
  static async validateNewClient(
    data
  ) {
    const errors = [];
    if (!data.company) {
      errors.push({ 
        id: "company",
        message: "Please enter a company name" 
      });
    }
    if (!data.contact) {
      errors.push({ 
        id: "contact",
        message: "Please enter a contact name" 
      });
    }
    else {
      if (!isValidName(data.contact)) {
        errors.push({ 
          id: "contact",
          message: "Please enter a valid contact name" 
        });
      }}
    if (!data.email) {
      errors.push({ 
        id: "email",
        message: "Please enter an email address" 
      });
    }
    else {
      if (!isValidEmail(data.email)) {
        errors.push({ 
          id: "email",
          message: "Please enter a valid email address" 
        });
      }
    }
    if (!data.phone) {
      errors.push({ 
        id: "phone",
        message: "Please enter a phone number" 
      });
    } else {
      if (isValidPhone(data.phone)) {
      errors.push({ 
        id: "phone",
        message: "Please enter a valid phone number" 
      });
    }}
    return (errors.length > 0) ? errors : [];
  }
}

export default ClientService;
