import { useEffect, useState } from "react";

import DashboardTotalsCard from "../components/Cards/DashboardTotalsCard";
import SourcesBreakdownCard from "../components/Cards/SourcesBreakdownCard";
import ServicesBreakdownCard from "../components/Cards/ServicesBreakdownCard";
import StatusBreakdownCard from "../components/Cards/StatusBreakdownCard";
import QuickActionsCard from "../components/Cards/QuickActionsCard";
import PresetsCard from "../components/Cards/PresetsCard";
import DateRangeFilterControl from "../components/Controls/DateRangeFilterControl";
import SourcesFilterControl from "../components/Controls/SourcesFilterControl";
import ServicesFilterControl from "../components/Controls/ServicesFilterControl";
import StatusFilterControl from "../components/Controls/StatusFilterControl";

import SalesToDateChart from "../components/Charts/SalesToDateChart";

import useLocalStorage from "../hooks/useLocalStorage";
import Layout from "../components/Layout";

import { getWeekNumber } from "../utils/dates";

import useProspects from "../hooks/useProspects";

const dateRangeDefault = {
  startDate: new Date(),
  endDate: new Date(),
};
dateRangeDefault.startDate.setMonth(dateRangeDefault.startDate.getMonth() - 6);

const Dashboard = () => {
  const [sourcesFiler, setSourcesFilter] = useLocalStorage("sources", []);
  const [statusFilter, setStatusFilter] = useLocalStorage("status", []);
  const [servicesFiler, setServicesFilter] = useLocalStorage("services", []);
  const [dateRangeFilter, setDateRangeFilter] = useLocalStorage(
    "daterange",
    dateRangeDefault
  );

  const [prospects, prospectsLoading, fetchProspects] = useProspects();

  const [filteredProspects, setFilteredProspects] = useState([]);

  const [salesStats, setSalesStats] = useState({});

  const applyFilters = (prospects) => {
    const prosectsByStatus = prospects.filter((prospect) => {
      if (!prospect.actionTimeline) {
        console.log(prospect);
        return;
      }

      // We have at least one action in the timeline
      if (prospect.actionTimeline && prospect.actionTimeline.length > 0) {
        // Get the latest action
        const latestAction =
          prospect.actionTimeline[prospect.actionTimeline.length - 1];

        // Is the latest action in the status filter?
        return statusFilter.includes(latestAction.action.label);
      }

      return statusFilter.includes(prospect.prospect_status.name);
    });

    const prosectsBySource = prosectsByStatus.filter((prospect) => {
      // if prospect.source starts with Referal, change it to Referral
      if (prospect.source.startsWith("Referral")) {
        prospect.source = "Referral";
      }
      return sourcesFiler.includes(prospect.source);
    });

    const prosectsByService = prosectsBySource.filter((prospect) => {
      return servicesFiler.includes(prospect.serviceType);
    });

    setFilteredProspects(prosectsByService);
  };

  const calculateSalesStats = (prospects) => {
    const totalsByWeek = new Map();
    const totalsByMonth = new Map();

    const weekToday = getWeekNumber(new Date());
    const monthToday = new Date().getMonth() + 1;

    if (!prospects) {
      return;
    }

    // loop through prospect
    prospects.forEach((prospect) => {
      if (!prospect.actionTimeline) {
        console.log(prospect);
        return;
      }
      // Look at the timeline to see the date it converted
      const lastAction =
        prospect.actionTimeline[prospect.actionTimeline.length - 1];

      // is the last action converted?
      if (lastAction.action.label !== "Converted") {
        return;
      }

      // get the Week Number from the last Action date
      const weekNo = getWeekNumber(new Date(lastAction.date));

      // is it for the current week or within the last 4 weeks?
      if (weekNo >= weekToday - 6 && weekNo <= weekToday) {
        if (totalsByWeek.has(weekNo)) {
          const week = totalsByWeek.get(weekNo);
          week.total += parseFloat(prospect.estimated_value);
          week.sales++;
        } else {
          totalsByWeek.set(weekNo, {
            week: weekNo,
            total: parseFloat(prospect.estimated_value),
            sales: 1,
          });
        }

        // get the totals for the current week
        if (totalsByWeek.get(weekToday)) {
          const thisWeek = totalsByWeek.get(weekToday);
          salesStats.thisWeek = thisWeek;
        } else {
          salesStats.thisWeek = {
            week: weekToday,
            total: 0,
            sales: 0,
          };
        }
      }

      // get the Month from the Date
      // get the Week Number from the last Action date
      const monthNo = new Date(lastAction.date).getMonth() + 1;
      if (totalsByMonth.has(monthNo)) {
        const month = totalsByMonth.get(monthNo);

        month.total += parseFloat(prospect.estimated_value);
        month.sales++;
      } else {
        const newMonth = {
          month: monthNo,
          total: parseFloat(prospect.estimated_value) || 0,
          sales: 1,
        };
        totalsByMonth.set(monthNo, newMonth);
      }

      // get the totals for the current month
      if (totalsByMonth.get(monthToday)) {
        const thisMonth = totalsByMonth.get(monthToday);
        salesStats.thisMonth = thisMonth;
      } else {
        salesStats.thisMonth = {
          month: monthToday,
          total: 0,
          sales: 0,
        };
      }
    });

    // co nvert the map to an array of objects
    const weekArray = Array.from(totalsByWeek.entries()).map(
      ([week, { total, sales }]) => ({
        week: week,
        total,
        sales,
      })
    );

    // sort the array by week number
    weekArray.sort((a, b) => a.week - b.week);

    salesStats.byWeek = weekArray;

    // co nvert the map to an array of objects
    const monthArray = Array.from(totalsByMonth.entries()).map(
      ([month, { total, sales }]) => ({
        monthName: month.toLocaleString("default", { month: "long" }),
        month: month,
        total,
        sales,
      })
    );

    monthArray.sort((a, b) => a.month - b.month);
    salesStats.byMonth = monthArray;
    setSalesStats(salesStats);
  };

  useEffect(() => {
    fetchProspects(dateRangeFilter);
  }, [dateRangeFilter]);

  useEffect(() => {
    applyFilters(prospects);
    calculateSalesStats(prospects);
  }, [prospects, sourcesFiler, statusFilter, servicesFiler]);

  return (
    <Layout>
      <div className="flex flex-col gap-4 items-center justify-center lg:flex-row mb-4">
        <SourcesFilterControl
          sources={sourcesFiler}
          setSources={setSourcesFilter}
        />
        <ServicesFilterControl
          services={servicesFiler}
          setServices={setServicesFilter}
        />
        <StatusFilterControl
          status={statusFilter}
          setStatus={setStatusFilter}
        />
        <DateRangeFilterControl
          dateRange={dateRangeFilter}
          setDateRange={setDateRangeFilter}
        />
      </div>
      {prospectsLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="flex flex-col gap-4 items-center justify-center lg:flex-row">
            <SourcesBreakdownCard prospects={filteredProspects} />
            <ServicesBreakdownCard prospects={filteredProspects} />
            <StatusBreakdownCard prospects={filteredProspects} />
            <DashboardTotalsCard
              prospects={filteredProspects}
              totals={salesStats}
            />
          </div>
          <div className="flex flex-col gap-4 items-center justify-center lg:flex-row mt-5">
            <PresetsCard
              dateRangeFilter={setDateRangeFilter}
              sourcesFilter={setSourcesFilter}
              statusFilter={setStatusFilter}
            />
            <SalesToDateChart stats={salesStats} />
            <QuickActionsCard />
          </div>
        </>
      )}
    </Layout>
  );
};

export default Dashboard;
