import { useEffect } from "react";

import AutoCompleteInputControl from "./AutoCompleteInputControl";

import useClients from "../../hooks/useClients";

const ClientAutoCompleteInputControl = ({
  selectedClient,
  setSelectedClient,
}) => {
  const [clients, clientsLoading, fetchClients, createClient, status] =
    useClients();

  useEffect(() => {
    fetchClients({});
  }, []);

  return (
    <>
      {clientsLoading ? (
        <p>Loading...</p>
      ) : (
        <AutoCompleteInputControl
          selectionData={clients}
          selectedItem={selectedClient}
          setSelectedItem={setSelectedClient}
        />
      )}
    </>
  );
};

export default ClientAutoCompleteInputControl;
