import { Card, CardBody, Typography, Button } from "@material-tailwind/react";
import { sourcesList, statusList } from "../../config";

const PresetsCard = ({ dateRangeFilter, sourcesFilter, statusFilter }) => {
  const handlePresetClick = (preset) => {
    switch (preset) {
      case "ACTIVE_PROSPECTS_IN_PROGRESS":
        sourcesFilter(sourcesList);
        statusFilter([
          "In Progress",
          "New Lead",
          "Waiting to quote",
          "Part quoted",
        ]);
        break;
      case "QUOTED_AND_FOLLOWED_UP":
        sourcesFilter(sourcesList);
        statusFilter(["Quoted", "Followed Up"]);
        break;
      case "ALL_PROSPECTS_FOR_THE_LAST_YEAR":
        sourcesFilter(sourcesList);
        statusFilter(statusList);
        dateRangeFilter({
          startDate: new Date(),
          endDate: new Date(),
        });
        break;
      case "SALES_FOR_THE_LAST_3_MONTHS":
        sourcesFilter(sourcesList);
        statusFilter(["Converted"]);

        // get a date 12 months ago from today
        const fromDateMinus3 = new Date();
        fromDateMinus3.setMonth(fromDateMinus3.getMonth() - 3);

        dateRangeFilter({
          startDate: fromDateMinus3,
          endDate: new Date(),
        });
        break;
      case "SALES_FOR_THE_LAST_12_MONTHS":
        sourcesFilter(sourcesList);
        statusFilter(["Converted"]);

        // get a date 12 months ago from today
        const fromDateMinus12 = new Date();
        fromDateMinus12.setMonth(fromDateMinus12.getMonth() - 12);

        dateRangeFilter({
          startDate: fromDateMinus12,
          endDate: new Date(),
        });
        break;
      default:
        break;
    }
  };
  return (
    <Card
      color="white"
      variant="gradient"
      className="w-full max-w-[30rem] p-8 min-h-64"
    >
      <CardBody className="p-0">
        <Typography variant="h5" color="blue-gray" className="mb-2">
          Quick Look
        </Typography>
        <Button
          variant="text"
          onClick={() => handlePresetClick("ACTIVE_PROSPECTS_IN_PROGRESS")}
        >
          Active prospects in progress
        </Button>
        <Button
          variant="text"
          onClick={() => handlePresetClick("QUOTED_AND_FOLLOWED_UP")}
        >
          Quoted and Followed Up
        </Button>
        <Button
          variant="text"
          onClick={() => handlePresetClick("ALL_PROSPECTS_FOR_THE_LAST_YEAR")}
        >
          All prospects for the last year
        </Button>
        <Button
          variant="text"
          onClick={() => handlePresetClick("SALES_FOR_THE_LAST_3_MONTHS")}
        >
          Sales for the last 3 months
        </Button>
        <Button
          variant="text"
          onClick={() => handlePresetClick("SALES_FOR_THE_LAST_12_MONTHS")}
        >
          Sales for the last 12 months
        </Button>
      </CardBody>
    </Card>
  );
};

export default PresetsCard;
