import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Chip,
} from "@material-tailwind/react";
import SourcesListMenu from "../Menus/SourcesListMenu";

import { sourcesList } from "../../config";

const SourcesFilterControl = ({ sources, setSources }) => {
  const renderSourcesSummary = () => {
    if (sources.length === 0) {
      return <Chip color="gray" size="sm" value="none selected" />;
    } else if (sources.length === 1) {
      return <Chip color="gray" size="sm" value={sources[0]} />;
    } else if (sources.length === sourcesList.length) {
      return <Chip color="gray" size="sm" value="all selected" />;
    } else if (sources.length > 1) {
      return (
        <Chip color="gray" size="sm" value={`${sources.length} selected`} />
      );
    }
  };

  const renderSourcesTooltipText = () => {
    return sources ? sources.join(" | ") : "none selected";
  };

  return (
    <Card className="w-full max-w-[30rem]">
      <CardBody>
        <div className="flex justify-between items-center">
          <Typography variant="h5" color="blue-gray" className="">
            Sources
          </Typography>
          <SourcesListMenu sources={sources} setSources={setSources} />
        </div>

        <div className="flex ">
          <Tooltip content={renderSourcesTooltipText()} placement="top">
            {renderSourcesSummary()}
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
};

export default SourcesFilterControl;
