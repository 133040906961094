import React, { useState, createContext, useContext } from "react";

// create a context object
const SessionContext = createContext(null);

const sessionInfo = {
  user_id: "",
  user_name: "",
  email: "",
  avatar: "",
  date_from: "",
  date_to: "",
  service: "",
  status: "",
  recent: [],
};

// create a custom hook to access the context
export const useSession = () => useContext(SessionContext);

// create a provider component
export default function SessionProvider({ children }) {
  
  const [session, setSession] = useState(sessionInfo);
  
  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
