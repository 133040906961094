import { useState, useEffect } from "react";
import { Card, CardBody, Typography } from "@material-tailwind/react";
import { formatCurrency } from "../../utils/numbers";

const StatusBreakdownCard = ({ prospects }) => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const calculateStats = () => {
      const statusList = new Map();

      prospects.forEach((prospect) => {
        // get the newest action from prospect actionTimeline
        const latestAction =
          prospect.actionTimeline[prospect.actionTimeline.length - 1];

        const statusLabel = latestAction.action.label;
        if (statusList.has(statusLabel)) {
          const status = statusList.get(statusLabel);
          status.value += parseFloat(prospect.estimated_value);
          status.qty += 1;
          statusList.set(statusLabel, status);
        } else {
          statusList.set(statusLabel, {
            value: parseFloat(prospect.estimated_value),
            qty: 1,
          });
        }
      });

      // co nvert the map to an array of objects
      const statusArray = Array.from(statusList.entries()).map(
        ([status, { value, qty }]) => ({
          status: status,
          value,
          qty,
        })
      );

      const totalValue = Array.from(statusList.values()).reduce(
        (acc, status) => acc + status.value,
        0
      );

      const statusWithPercentage = Array.from(statusList.entries()).map(
        ([status, { value, qty }]) => ({
          status,
          value,
          qty,
          percentage: ((value / totalValue) * 100).toFixed(0),
        })
      );

      // re-sort the array by value, highest first
      statusWithPercentage.sort((a, b) => b.value - a.value);

      setStats(statusWithPercentage);
    };
    calculateStats();
  }, [prospects]);

  return (
    <Card color="gray" variant="gradient" className="w-full max-w-[30rem] p-8">
      <CardBody className="p-0 min-h-52">
        <div className="flex gap-4">
          <div className="flex items-center min-w-[250px]">
            <Typography className="font-normal">Status</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Value</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">Qty</Typography>
          </div>
          <div className="flex items-center min-w-[40px] justify-center">
            <Typography className="font-normal">%</Typography>
          </div>
        </div>
        {stats && stats.length > 0 ? (
          stats.map((stat, index) => (
            <div key={index} className="flex gap-4">
              <div className="flex items-center min-w-[250px]">
                <Typography className="font-normal">{stat.status}</Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  £{formatCurrency(stat.value)}
                </Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">{stat.qty}</Typography>
              </div>
              <div className="flex items-center min-w-[40px] justify-center">
                <Typography className="font-normal">
                  {stat.percentage}%
                </Typography>
              </div>
            </div>
          ))
        ) : (
          <div className="flex gap-4">
            <div className="flex items-center">
              <Typography className="font-normal min-w-40">No Data</Typography>
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default StatusBreakdownCard;
