import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout";
import DateRangeFilterControl from "../components/Controls/DateRangeFilterControl";
import SourcesFilterControl from "../components/Controls/SourcesFilterControl";
import ServicesFilterControl from "../components/Controls/ServicesFilterControl";
import StatusFilterControl from "../components/Controls/StatusFilterControl";

import ProspectsDataTable from "../components/Tables/ProspectsDataTable";
import useProspects from "../hooks/useProspects";
import useLocalStorage from "../hooks/useLocalStorage";

const dateRangeDefault = {
  startDate: new Date(),
  endDate: new Date(),
};
dateRangeDefault.startDate.setMonth(dateRangeDefault.startDate.getMonth() - 6);

const Prospects = () => {
  const [sourcesFiler, setSourcesFilter] = useLocalStorage("sources", []);
  const [statusFilter, setStatusFilter] = useLocalStorage("status", []);
  const [servicesFiler, setServicesFilter] = useLocalStorage("services", []);
  const [dateRangeFilter, setDateRangeFilter] = useLocalStorage(
    "filter_dates",
    dateRangeDefault
  );

  const [prospects, , fetchProspects] = useProspects();

  const navigate = useNavigate();

  const [filteredProspects, setFilteredProspects] = useState([]);

  const applyFilters = (prospects) => {
    const filteredProspects = [];
    prospects.forEach((prospect) => {
      const updatedProspect = prospect;
      // We have at least one action in the timeline
      if (prospect.actionTimeline && prospect.actionTimeline.length > 0) {
        // sort the actions by date so we get most recent first

        // Get the latest action
        const latestAction =
          prospect.actionTimeline[prospect.actionTimeline.length - 1];

        // Is the latest action in the status filter?
        if (statusFilter.includes(latestAction.action.label)) {
          updatedProspect.lastStatus = latestAction.action.label;
          filteredProspects.push(updatedProspect);
        }
      }

      if (statusFilter.includes(prospect.prospect_status.name)) {
        updatedProspect.lastStatus = prospect.prospect_status.name;
        filteredProspects.push(updatedProspect);
      }
    });

    const prosectsBySource = filteredProspects.filter((prospect) => {
      // if prospect.source starts with Referal, change it to Referral
      if (prospect.source.startsWith("Referral")) {
        prospect.source = "Referral";
      }
      return sourcesFiler.includes(prospect.source);
    });

    const prosectsByService = prosectsBySource.filter((prospect) => {
      return servicesFiler.includes(prospect.serviceType);
    });

    setFilteredProspects(prosectsByService);
  };

  useEffect(() => {
    fetchProspects(dateRangeFilter);
  }, [dateRangeFilter]);

  useEffect(() => {
    applyFilters(prospects);
  }, [prospects, sourcesFiler, statusFilter, servicesFiler]);

  const onNewProspect = () => {
    navigate("/prospects/new");
  };

  const onOpenProspect = (id, prospect) => {
    window.location.href = prospect.permalink;
  };
  const onEditProspect = (id, prospect) => {
    window.location.href = `${prospect.permalink}edit`;
  };
  return (
    <Layout>
      <div className="flex flex-col gap-4 items-center justify-center lg:flex-row mb-4">
        <SourcesFilterControl
          sources={sourcesFiler}
          setSources={setSourcesFilter}
        />
        <ServicesFilterControl
          services={servicesFiler}
          setServices={setServicesFilter}
        />
        <StatusFilterControl
          status={statusFilter}
          setStatus={setStatusFilter}
        />
        <DateRangeFilterControl
          dateRange={dateRangeFilter}
          setDateRange={setDateRangeFilter}
        />
      </div>
      {filteredProspects ? (
        <>
          <ProspectsDataTable
            data={filteredProspects}
            onNewItem={onNewProspect}
            onOpenItem={onOpenProspect}
            onEditItem={onEditProspect}
          />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Layout>
  );
};

export default Prospects;
