import React, { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary'

function AppErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  const handleComponentError = (error, errorInfo) => {
    // You can also log the error to an error reporting service
    console.error('Error Boundary caught an error:', error, errorInfo);
    setHasError(true);
  };

  if (hasError) {
    // You can render any custom fallback UI
    return (
      <div>
        <h1>Something went wrong.</h1>
        <p>Please try refreshing the page or come back later.</p>
      </div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={handleComponentError}>
      {children}
    </ErrorBoundary>
  );
}

function ErrorFallback() {
  return (
    <div>
      <h1>Something went wrong.</h1>
      <p>Please try refreshing the page or come back later.</p>
    </div>
  );
}

export default AppErrorBoundary;
