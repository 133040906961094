import React from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
} from "@material-tailwind/react";

import { Link } from "react-router-dom";

const DrawerMenu = ({ drawState, setDrawState }) => {
  const openDrawer = () => setDrawState(true);
  const closeDrawer = () => setDrawState(false);

  return (
    <React.Fragment>
      <Button
        variant="text"
        className="flex items-center gap-2"
        onClick={openDrawer}
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 18L20 18"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M4 12L20 12"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M4 6L20 6"
            stroke="#000000"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </Button>
      <Drawer open={drawState} onClose={closeDrawer}>
        <div className="mb-2 flex items-center justify-between p-4">
          <Typography variant="h5" color="blue-gray">
            Options
          </Typography>
          <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <List>
          <Link to="/">
            <ListItem>
              <ListItemPrefix>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="h-5 w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 2.25a.75.75 0 000 1.5H3v10.5a3 3 0 003 3h1.21l-1.172 3.513a.75.75 0 001.424.474l.329-.987h8.418l.33.987a.75.75 0 001.422-.474l-1.17-3.513H18a3 3 0 003-3V3.75h.75a.75.75 0 000-1.5H2.25zm6.04 16.5l.5-1.5h6.42l.5 1.5H8.29zm7.46-12a.75.75 0 00-1.5 0v6a.75.75 0 001.5 0v-6zm-3 2.25a.75.75 0 00-1.5 0v3.75a.75.75 0 001.5 0V9zm-3 2.25a.75.75 0 00-1.5 0v1.5a.75.75 0 001.5 0v-1.5z"
                    clipRule="evenodd"
                  />
                </svg>
              </ListItemPrefix>
              Dashboard
            </ListItem>
          </Link>
          <Link to="/prospects">
            <ListItem>
              <ListItemPrefix>
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  data-name="Layer 1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="cls-1"
                    d="M30.68,19.05l-1.9.64-1.65-9.06,4.24-1.7a1,1,0,1,0-.74-1.86l-5,2A1,1,0,0,0,25,10.18l0,.18-2.39,1.19a1,1,0,0,1-1.05-.09L19.13,9.6a3,3,0,0,0-1.8-.6H14.62a2,2,0,0,0-1.78,1.09L11.05,10a1,1,0,0,0-.5.11l-1.67.83L6,10.22l0-.13a1,1,0,0,0-.55-1l-4-2a1,1,0,0,0-.9,1.78l3.39,1.7-.82,9.06-1.8-.6A1,1,0,1,0,.68,21l3,1A1.19,1.19,0,0,0,4,22a1.1,1.1,0,0,0,.55-.16A1,1,0,0,0,5,21.09l.08-.93,1.29.64a3.07,3.07,0,0,0,1.09,1.89l5.77,4.14a2,2,0,0,0,2.84-.3,2.91,2.91,0,0,0,2.36-.13l5.41-2.7A2.31,2.31,0,0,0,25.08,22a1.71,1.71,0,0,0,0-.32l1.9-.63,0,.12a1,1,0,0,0,.47.68A1,1,0,0,0,28,22a1.19,1.19,0,0,0,.32-.05l3-1a1,1,0,1,0-.64-1.9Zm-22,2.06A1,1,0,0,1,8.38,20a.2.2,0,0,1,.15-.14c.06,0,.14,0,.26.08l6.05,4.37-.33,1,0,0Zm14.26.8-5.41,2.7a1,1,0,0,1-.76.06,2,2,0,0,0-.72-1.92l-6-4.37A2.22,2.22,0,0,0,8,18a2.19,2.19,0,0,0-1.18.84l-1.36-.68a.61.61,0,0,0-.18-.05l.53-5.83,3,.74L9,13a1,1,0,0,0,.45-.11L11.21,12l.65,0-.75,1.51a1,1,0,0,0,.44,1.34l.21.11a3,3,0,0,0,3.83-1h1l6.38,7.29a1.19,1.19,0,0,0,.09.15.26.26,0,0,1,.08.25A.27.27,0,0,1,23,21.91Zm1.36-2.07-6.56-7.5A1,1,0,0,0,17,12H15a1,1,0,0,0-.89.55l-.11.21a1,1,0,0,1-.5.47L14.62,11h2.71a1,1,0,0,1,.6.2l2.48,1.86a3,3,0,0,0,3.14.28l1.87-.93,1.21,6.66Z"
                  />
                </svg>
              </ListItemPrefix>
              Prospects
            </ListItem>
          </Link>
          <Link to="/clients">
            <ListItem>
              <ListItemPrefix>
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.02747 10.022L6.68521 12.9482C7.17251 13.338 7.41617 13.5329 7.68749 13.6715C7.9282 13.7945 8.18443 13.8844 8.44921 13.9387C8.74767 14 9.0597 14 9.68375 14H14.3163C14.9403 14 15.2523 14 15.5508 13.9387C15.8156 13.8844 16.0718 13.7945 16.3125 13.6715C16.5838 13.5329 16.8275 13.338 17.3148 12.9482L20.9725 10.022M3.02747 10.022C3 10.4895 3 11.0642 3 11.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V11.8C21 11.0642 21 10.4895 20.9725 10.022M3.02747 10.022C3.06272 9.42205 3.1432 8.99871 3.32698 8.63803C3.6146 8.07354 4.07354 7.6146 4.63803 7.32698C5.27976 7 6.11984 7 7.8 7H8M20.9725 10.022C20.9373 9.42205 20.8568 8.99871 20.673 8.63803C20.3854 8.07354 19.9265 7.6146 19.362 7.32698C18.7202 7 17.8802 7 16.2 7H16M8 7V6C8 4.34315 9.34315 3 11 3H13C14.6569 3 16 4.34315 16 6V7M8 7H16"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ListItemPrefix>
              Customers
            </ListItem>
          </Link>
          <Link
            to="https://hub.fl1digital.com/tasks"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </ListItemPrefix>
              Scheduling
            </ListItem>
          </Link>
          <Link
            to="https://meet.fl1digital.com/meet/jason"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  fill="#000000"
                  width="20px"
                  height="20px"
                  viewBox="0 0 52 52"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill-rule="evenodd">
                    <path d="m47.6 27.92h-15.33a2.86 2.86 0 0 0 -2.87 2.87v11.5a2.87 2.87 0 0 0 2.87 2.88h15.33a2.88 2.88 0 0 0 2.88-2.88v-11.5a2.87 2.87 0 0 0 -2.88-2.87zm1 14.37a1 1 0 0 1 -1 1h-15.33a1 1 0 0 1 -1-1v-9.58a1 1 0 0 1 1-1h15.33a1 1 0 0 1 1 1z" />
                    <path d="m27.48 16.74c0-5.31-3.13-9.91-8.63-9.91s-8.62 4.6-8.62 9.91a9.71 9.71 0 0 0 2.82 7.08 5.71 5.71 0 0 1 1.95 3.66c0 1.41-.46 2.58-3.6 4-4.49 2-8.76 4.26-8.87 8.49a4.91 4.91 0 0 0 4.64 5.19h20.12a5.73 5.73 0 0 1 -.77-2.88v-10.68l-.25-.12c-3-1.42-3.6-2.7-3.6-4a5.27 5.27 0 0 1 1.91-3.66 9.75 9.75 0 0 0 2.9-7.08z" />
                  </g>
                </svg>
              </ListItemPrefix>
              Meet
            </ListItem>
          </Link>
          <Link
            to="https://monitor.fl1digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 13H8.5L10 15L12 9L14 13H17M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </ListItemPrefix>
              Site Monitor
            </ListItem>
          </Link>
          <Link
            to="https://qr.fl1digital.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItem>
              <ListItemPrefix>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 9h6V3H3zm1-5h4v4H4zm1 1h2v2H5zm10 4h6V3h-6zm1-5h4v4h-4zm1 1h2v2h-2zM3 21h6v-6H3zm1-5h4v4H4zm1 1h2v2H5zm15 2h1v2h-2v-3h1zm0-3h1v1h-1zm0-1v1h-1v-1zm-10 2h1v4h-1v-4zm-4-7v2H4v-1H3v-1h3zm4-3h1v1h-1zm3-3v2h-1V3h2v1zm-3 0h1v1h-1zm10 8h1v2h-2v-1h1zm-1-2v1h-2v2h-2v-1h1v-2h3zm-7 4h-1v-1h-1v-1h2v2zm6 2h1v1h-1zm2-5v1h-1v-1zm-9 3v1h-1v-1zm6 5h1v2h-2v-2zm-3 0h1v1h-1v1h-2v-1h1v-1zm0-1v-1h2v1zm0-5h1v3h-1v1h-1v1h-1v-2h-1v-1h3v-1h-1v-1zm-9 0v1H4v-1zm12 4h-1v-1h1zm1-2h-2v-1h2zM8 10h1v1H8v1h1v2H8v-1H7v1H6v-2h1v-2zm3 0V8h3v3h-2v-1h1V9h-1v1zm0-4h1v1h-1zm-1 4h1v1h-1zm3-3V6h1v1z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
              </ListItemPrefix>
              QR Generator
            </ListItem>
          </Link>
          <Link to="/business-cards">
            <ListItem>
              <ListItemPrefix>
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M3 9h6V3H3zm1-5h4v4H4zm1 1h2v2H5zm10 4h6V3h-6zm1-5h4v4h-4zm1 1h2v2h-2zM3 21h6v-6H3zm1-5h4v4H4zm1 1h2v2H5zm15 2h1v2h-2v-3h1zm0-3h1v1h-1zm0-1v1h-1v-1zm-10 2h1v4h-1v-4zm-4-7v2H4v-1H3v-1h3zm4-3h1v1h-1zm3-3v2h-1V3h2v1zm-3 0h1v1h-1zm10 8h1v2h-2v-1h1zm-1-2v1h-2v2h-2v-1h1v-2h3zm-7 4h-1v-1h-1v-1h2v2zm6 2h1v1h-1zm2-5v1h-1v-1zm-9 3v1h-1v-1zm6 5h1v2h-2v-2zm-3 0h1v1h-1v1h-2v-1h1v-1zm0-1v-1h2v1zm0-5h1v3h-1v1h-1v1h-1v-2h-1v-1h3v-1h-1v-1zm-9 0v1H4v-1zm12 4h-1v-1h1zm1-2h-2v-1h2zM8 10h1v1H8v1h1v2H8v-1H7v1H6v-2h1v-2zm3 0V8h3v3h-2v-1h1V9h-1v1zm0-4h1v1h-1zm-1 4h1v1h-1zm3-3V6h1v1z" />
                  <path fill="none" d="M0 0h24v24H0z" />
                </svg>
              </ListItemPrefix>
              Business Cards
            </ListItem>
          </Link>
          <ListItem>
            <ListItemPrefix>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path
                  fillRule="evenodd"
                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                  clipRule="evenodd"
                />
              </svg>
            </ListItemPrefix>
            Profile
          </ListItem>
        </List>
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerMenu;
