import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Chip,
} from "@material-tailwind/react";
import ServicesListMenu from "../Menus/ServicesListMenu";

import { servicesList } from "../../config";

const ServicesFilterControl = ({ services, setServices }) => {
  const renderServicesSummary = () => {
    if (services.length === 0) {
      return <Chip color="gray" size="sm" value="none selected" />;
    } else if (services.length === 1) {
      return <Chip color="gray" size="sm" value={services[0]} />;
    } else if (services.length === servicesList.length) {
      return <Chip color="gray" size="sm" value="all selected" />;
    } else if (services.length > 1) {
      return (
        <Chip color="gray" size="sm" value={`${services.length} selected`} />
      );
    }
  };

  const renderServicesTooltipText = () => {
    return services ? services.join(" | ") : "none selected";
  };

  return (
    <Card className="w-full max-w-[30rem]">
      <CardBody>
        <div className="flex justify-between items-center">
          <Typography variant="h5" color="blue-gray" className="">
            Services
          </Typography>
          <ServicesListMenu services={services} setServices={setServices} />
        </div>

        <div className="flex ">
          <Tooltip content={renderServicesTooltipText()} placement="top">
            {renderServicesSummary()}
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
};

export default ServicesFilterControl;
