import { useState, useEffect } from "react";

import { Typography, Card } from "@material-tailwind/react";

import Layout from "../components/Layout";
import AlertDismissible from "../components/Misc/AlertDismissible";
import ClientSelectionControl from "../components/Controls/ClientSelectionControl";
import ProjectSelectionControl from "../components/Controls/ProjectSelectionControl";
import NextStepSelectionControl from "../components/Controls/NextStepSelectionControl";

import useProspects from "../hooks/useProspects";
import useClients from "../hooks/useClients";
import useTasks from "../hooks/useTasks";
import prospectActionOptions from "../config/prospectActionOptions";

import ClientService from "../services/Client";
import MailChimpService from "../services/MailChimp";

import { getNextWorkingDay } from "../utils/dates";

const defaultClientData = {
  title: "",
  contact: "",
  email: "",
  phone: "",
  company: "",
  source: "Google Ad",
};

const defaultProjectData = {
  service: "New website",
  estimatedValue: 1500,
  details: "",
};

const defaultNextStepData = {
  followUpType: "Quote",
  followUpStartTime: getNextWorkingDay({ hours: 10, minutes: 0 }),
  followUpEndTime: getNextWorkingDay({ hours: 11, minutes: 0 }),
  followUpNotes: "",
};

const Prospect = () => {
  const [, , , createProspect, createProspectAction] = useProspects();
  const [, , , createClient] = useClients();
  const [, , , createTask] = useTasks();

  const [clientData, setClientData] = useState(defaultClientData);
  const [projectData, setProjectData] = useState(defaultProjectData);
  const [nextStepData, setNextStepData] = useState(defaultNextStepData);

  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const showMessage = (message) => {
    const messageText =
      message || errors.length > 0
        ? errors.map((error) => error.message).join(", ")
        : "";

    setMessage(messageText);
    setTimeout(() => {
      setErrors([]);
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    if (errors.length > 0) {
      showMessage();
    }
  }, [errors]);

  const onClearData = () => {
    setClientData(defaultClientData);
    setProjectData(defaultProjectData);
    setNextStepData(defaultNextStepData);
  };

  const validateNewProspect = async () => {
    // Validate the client data if we need to create a new client
    const errors = [];
    if (!clientData.id) {
      const err = await ClientService.validateNewClient(clientData);
      err.map((error) => errors.push(error));

      // Validate the project data
      if (!clientData.source) {
        errors.push({
          id: "source",
          message: "Please choose a source for the client",
        });
      }
    }

    // Validate the project data
    if (!projectData.service) {
      errors.push({
        id: "service",
        message: "Please choose a suitable service",
      });
    }

    if (!projectData.details) {
      errors.push({
        id: "details",
        message: "Enter some details of the project",
      });
    }

    setErrors(errors);
    return errors.length === 0;
  };

  const onCreateProspect = async () => {
    // check we have all the required data first
    const isValid = await validateNewProspect();
    if (!isValid) {
      return;
    }

    setIsSaving(true);

    try {
      // if it's a new client, create it
      if (!clientData.id) {
        const newClient = await createClient(clientData);
        if (newClient.id) {
          clientData.id = newClient.id;

          // subscribe to mailing list
          const mailChimpResponse =
            await MailChimpService.subscribeToMailingList(
              clientData.contact,
              clientData.email
            );
        } else {
          setErrors(newClient.errors);
          return;
        }
      } else {
        clientData.company = clientData.title;
      }

      // create the prospect
      const newProspect = await createProspect({
        title: `${clientData.company} - ${projectData.service} `,
        authorId: 2,
        client: clientData.id,
        source: clientData.source || "Existing Customer",
        status: 6,
        account_manager: 2,
        service_type: projectData.service,
        estimated_value: projectData.estimatedValue,
      });

      if (newProspect.id) {
        // create a date and set to today
        const today = new Date();

        const newProspectAction = await createProspectAction({
          prospect: newProspect.id,
          action: prospectActionOptions.OptionNewLead,
          date: today,
          notes: projectData.details,
        });
      } else {
        setErrors(newProspect.errors);
        return;
      }

      const taskNotes = `Project Details: 
${projectData.details}
https://hub2.fl1digital.com/?p=${newProspect.id}

----
${nextStepData.followUpNotes || ""}
`;
      const taskData = {
        title: nextStepData.followUpType.toUpperCase(),
        authorId: 2,
        startDate: nextStepData.followUpStartTime,
        endDate: nextStepData.followUpEndTime,
        projectId: newProspect.id,
        notes: taskNotes,
      };

      // create the task
      const newTask = await createTask(taskData);

      if (newTask) {
        const msg = {
          message: `Prospect successfully created`,
        };
        setErrors([msg]);
      }

      // reset all the data for the next prospect
      onClearData();

      //setErrors([msg]);
    } catch (error) {
      setErrors([error.message]);
      showMessage();
    } finally {
      setIsSaving(false);
      showMessage();
    }
  };

  return (
    <Layout>
      {message && <AlertDismissible>{message}</AlertDismissible>}

      <div className="flex flex-col gap-4 justify-center mb-4 lg:flex-row">
        <Card color="transparent" shadow={false} className="">
          <Typography variant="h4" color="blue-gray">
            Who's the client?
          </Typography>
          <Typography color="gray" className="mt-1 font-normal">
            Let's get some basic information about the client.
          </Typography>
          <ClientSelectionControl
            selectedClient={clientData}
            setSelectedClient={setClientData}
            errors={errors}
          />
        </Card>

        <Card color="transparent" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            What's the Project?
          </Typography>
          <Typography color="gray" className="mt-1 font-normal">
            What's the initial scope of the project?.
          </Typography>
          <ProjectSelectionControl
            selectedProject={projectData}
            setSelectedProject={setProjectData}
            errors={errors}
          />
        </Card>

        <Card color="transparent" shadow={false}>
          <Typography variant="h4" color="blue-gray">
            What's the next step?
          </Typography>
          <Typography color="gray" className="mt-1 font-normal">
            What are we going to do next?
          </Typography>
          <NextStepSelectionControl
            selectedNextStep={nextStepData}
            setSelectedNextStep={setNextStepData}
            onCreateProspect={onCreateProspect}
            isSaving={isSaving}
          />
        </Card>
      </div>
    </Layout>
  );
};

export default Prospect;
