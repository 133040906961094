const statusList = [
  "New Lead",
  "Waiting to quote",
  "In Progress",
  "Long term consideration",
  "Quoted",
  "Followed up",
  "Converted",
  "Not needed",
  "Ghosted, never heard back",
  "Didn't win",
];

export default statusList;
