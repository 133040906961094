import { useState } from "react";
import { Card, CardBody, Switch } from "@material-tailwind/react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
  options: {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  },
};

const SalesToDateChart = ({ stats }) => {
  const [byWeek, setByWeek] = useState(true);
  if (!stats) return null;

  const chartData = {};

  if (byWeek) {
    chartData.labels = stats.byWeek?.map((item) => `Week ${item.week}`);
    chartData.data = stats.byWeek?.map((item) => item.total);
    chartData.label = "Sales By Week";
    chartData.backgroundColor = "rgb(255, 99, 132, 0.2)";
    chartData.borderColor = "rgb(255, 99, 132, 1)";
  } else {
    chartData.labels = stats.byMonth?.map((item) => `Month ${item.monthName}`);
    chartData.data = stats.byMonth?.map((item) => item.total);
    chartData.label = "Sales By Month";
    chartData.backgroundColor = "rgb(255, 99, 132, 0.2)";
    chartData.borderColor = "rgb(115, 99, 255, 1)";
  }

  const data = {
    labels: chartData.labels,
    datasets: [
      {
        label: chartData.label,
        data: chartData.data,
        borderColor: chartData.borderColor,
        backgroundColor: chartData.backgroundColor,
      },
    ],
  };

  return (
    <Card
      color="white"
      variant="gradient"
      className="w-full max-w-[30rem] p-8 min-h-72"
    >
      <CardBody className="p-0">
        <Line options={options} data={data} />
        <Switch
          checked={byWeek}
          onChange={() => setByWeek(!byWeek)}
          label="Totals By Week"
        />
      </CardBody>
    </Card>
  );
};

export default SalesToDateChart;
