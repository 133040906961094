"use client";

import { useState } from "react";
import ProspectService from "../services/Prospect";

const useProspects = () => {
  const [prospects, setProspects] = useState([]);
  const [prospectsLoading, setProspectsLoading] = useState(true);

  const fetchProspects = async (options) => {
    try {
      setProspectsLoading(true);

      // are we looking for a single prospect?
      if (options.id) {
        const data = await ProspectService.getProspect(options.id);
        setProspectsLoading(false);
        setProspects([data]);
      } else {
        const data = await ProspectService.getProspects(
          options.startDate,
          options.startDate
        );

        setProspectsLoading(false);

        if (data) {
          setProspects(data);
        }
      }
    } catch (error) {
      const errors = [{ message: error.message }];
      return { errors, rows: 0 };
    }
  };

  const createProspect = async (options) => {
    const result = {};

    const errors = await ProspectService.validateNewProspect(options);
    console.log("Errors:", errors);
    if (errors.length > 0) {
      result.errors = errors;
      result.id = 0;
      return { errors, id: 0 };
    }

    try {
      const id = await ProspectService.createProspect(
        options.title,
        options.authorId,
        options.client,
        options.source,
        options.status,
        options.account_manager,
        options.service_type,
        options.estimated_value
      );

      const newProspect = {
        id: id,
        ...options,
      };
      const allProspects = [...prospects, newProspect];

      setProspects(allProspects);

      return { errors: [], id: id };
    } catch (error) {
      const errors = [{ message: error.message }];
      return { errors, id: 0 };
    }
  };

  const createProspectAction = async (options) => {
    try {
      const data = await ProspectService.createProspectAction(
        options.prospect,
        options.action,
        options.date,
        options.notes
      );

      console.log(data);

      const allProspects = prospects.map((prospect) => {
        if (prospect.id === options.prospect) {
          prospect.actions.push(data);
        }
      });

      setProspects(allProspects);
      return { errors: [], id: options.prospect };
    } catch (error) {
      const errors = [{ message: error.message }];
      return { errors, id: 0 };
    }
  };

  return [
    prospects,
    prospectsLoading,
    fetchProspects,
    createProspect,
    createProspectAction,
  ];
};

export default useProspects;
