import { useEffect, useState } from "react";
import AuthService from "./services/Auth";
import SessionProvider from "./context/SessionContext";
import Dashboard from "./screens/Dashboard";
import Prospects from "./screens/Prospects";
import Clients from "./screens/Clients";
import Prospect from "./screens/Prospect";
import BusinessCards from "./screens/BusinessCards";
import Login from "./screens/Login";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  const [token, setToken] = useState("");

  const login = async () => {
    const token = await AuthService.LoginAnonymous();
    setToken(token);
  };

  useEffect(() => {
    login();
  }, []);

  return (
    <SessionProvider>
      {token ? (
        <Router>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/prospects" element={<Prospects />} />
            <Route path="/prospects/new" element={<Prospect />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/business-cards" element={<BusinessCards />} />
            <Route path="/cards" element={<BusinessCards />} />
          </Routes>
        </Router>
      ) : (
        <Login />
      )}
    </SessionProvider>
  );
}

export default App;
