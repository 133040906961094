import { useState } from "react";

import {
  Menu,
  MenuHandler,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
} from "@material-tailwind/react";

import useLocalStorage from "../../hooks/useLocalStorage";

import { statusList } from "../../config";

const StatusListMenu = ({ status, setStatus }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [statusSelections, setStatusSelections] = useLocalStorage(
    "status",
    status
  );

  const onUpdate = () => {
    setOpenMenu(false);
    setStatus(statusSelections);
  };

  const onStatusChange = (status) => {
    if (statusSelections.includes(status)) {
      setStatusSelections(statusSelections.filter((item) => item !== status));
    } else {
      setStatusSelections([...statusSelections, status]);
    }
  };

  const onSelectAll = () => {
    setSelectAll(!selectAll);
    setStatusSelections(selectAll ? statusList : []);
  };

  return (
    <Menu
      open={openMenu || false}
      handler={setOpenMenu}
      allowHover
      dismiss={{
        itemPress: false,
      }}
      placement="bottom-end"
    >
      <MenuHandler>
        <Button className="rounded-full" onClick={() => setOpenMenu(true)}>
          ...
        </Button>
      </MenuHandler>
      <MenuList>
        {statusList.map((item, index) => (
          <MenuItem className="p-0" key={index}>
            <label
              htmlFor={`status-item-${index}`}
              className="flex cursor-pointer items-center gap-2 p-2"
            >
              <Checkbox
                ripple={false}
                id={`status-item-${index}`}
                containerProps={{ className: "p-0" }}
                className="hover:before:content-none"
                onChange={() => onStatusChange(item)}
                checked={statusSelections.includes(item)}
              />
              {item}
            </label>
          </MenuItem>
        ))}
        <hr className="my-3" />

        <MenuItem className="p-0" key="select-all">
          <label
            htmlFor={`status-item-00`}
            className="flex cursor-pointer items-center gap-2 p-2"
          >
            <Checkbox
              ripple={false}
              id={`status-item-00`}
              containerProps={{ className: "p-0" }}
              className="hover:before:content-none"
              onChange={() => onSelectAll()}
              checked={!selectAll}
            />
            {"Select All"}
          </label>
        </MenuItem>

        <MenuItem className="update">
          <Button onClick={() => onUpdate()}>Update</Button>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default StatusListMenu;
