import { Card, CardBody, Typography, Button } from "@material-tailwind/react";

import { useNavigate } from "react-router-dom";

const ExternalLinkButton = ({ url, children }) => {
  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <Button color="white" size="lg" className="w-full" onClick={handleClick}>
      {children}
    </Button>
  );
};

const QuickActionsCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      color="white"
      variant="gradient"
      className="w-full max-w-[20rem] p-10"
    >
      <CardBody className="p-0">
        <Typography
          variant="h1"
          color="white"
          className="my-2 flex flex-col justify-center gap-1 text-3xl font-normal"
        >
          <Button
            color="red"
            size="lg"
            className="w-full"
            onClick={() => navigate("/prospects/new")}
          >
            New Prospect
          </Button>
          <ExternalLinkButton url="https://meet.fl1digital.com/">
            Book a Meeting
          </ExternalLinkButton>
          <Button
            color="white"
            size="lg"
            className="w-full"
            onClick={() => navigate("/prospects")}
          >
            View Prospects
          </Button>
          <ExternalLinkButton url="https://hub.fl1digital.com/tasks">
            Task Manager
          </ExternalLinkButton>
        </Typography>
      </CardBody>
    </Card>
  );
};
export default QuickActionsCard;
