import {
  Typography,
  Textarea,
  Input,
} from "@material-tailwind/react";

import ServicesSelectControl from "./ServicesSelectControl";

const ProjectSelectionControl = ({selectedProject, setSelectedProject, errors}) => {

    const onFieldChange = (e) => {
        setSelectedProject({
            ...selectedProject,
            [e.target.id]: e.target.value,
        });
    }

    return (
    <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
        <div className="mb-1 flex flex-col gap-6">
            <Typography variant="h6" color="blue-gray" className="-mb-3">
                Project Type
            </Typography>
            <ServicesSelectControl selection={selectedProject.service} onSelection={onFieldChange} hasError={errors?.find((error) => error.id === "service")} />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
                Estimated Vaue
            </Typography>
            <Input
                size="md"
                type="number"
                id="estimatedValue"
                placeholder="1000"
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                onChange={onFieldChange}
                value={selectedProject.estimatedValue}
                labelProps={{
                    className: "before:content-none after:content-none",
                }}
            />
            <Typography variant="h6" color="blue-gray" className="-mb-3">
                Project Details
            </Typography>
            <Textarea 
                error={errors?.find((error) => error.id === "details")}
                variant="outlined" 
                label="Details of the project" 
                rows={14}
                id="details"
                value={selectedProject.details}
                onChange={onFieldChange}
            />
        </div>
        
    </form>
  )
}

export default ProjectSelectionControl