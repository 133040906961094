import { useState } from "react";

import { Select, Option } from "@material-tailwind/react";

import sourceList from "../../config/sourceOptions";

const ContactSourceSelect = ({ selection, onSelection, hasError }) => {
  const onChange = (val) => {
    const data = {
      target: {
        id: "source",
        value: val,
      },
    };
    onSelection(data);
  };

  return (
    <Select
      size="lg"
      label="Where did you hear about us?"
      value={selection}
      onChange={(val) => onChange(val)}
      error={hasError}
    >
      {sourceList.map((source, index) => {
        return (
          <Option key={index} value={source}>
            {source}
          </Option>
        );
      })}
    </Select>
  );
};

export default ContactSourceSelect;
