import { useState } from "react";

import {
  Menu,
  MenuHandler,
  Button,
  MenuList,
  MenuItem,
  Checkbox,
} from "@material-tailwind/react";

import useLocalStorage from "../../hooks/useLocalStorage";

import { servicesList } from "../../config";

const ServicesListMenu = ({ services, setServices }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectAll, setSelectAll] = useState(true);
  const [servicesSelections, setServicesSelections] = useLocalStorage(
    "services",
    services
  );

  const onUpdate = () => {
    setOpenMenu(false);
    setServices(servicesSelections);
  };

  const onServicesChange = (service) => {
    if (servicesSelections.includes(service)) {
      setServicesSelections(
        servicesSelections.filter((item) => item !== service)
      );
    } else {
      setServicesSelections([...servicesSelections, service]);
    }
  };

  const onSelectAll = () => {
    setSelectAll(!selectAll);
    setServicesSelections(selectAll ? servicesList : []);
  };

  return (
    <Menu
      open={openMenu || false}
      handler={setOpenMenu}
      allowHover
      dismiss={{
        itemPress: false,
      }}
      placement="bottom-end"
    >
      <MenuHandler>
        <Button className="rounded-full" onClick={() => setOpenMenu(true)}>
          ...
        </Button>
      </MenuHandler>
      <MenuList>
        {servicesList.map((item, index) => (
          <MenuItem className="p-0" key={`item-${index}`}>
            <label
              htmlFor={`services-item-${index}`}
              className="flex cursor-pointer items-center gap-2 p-2"
            >
              <Checkbox
                ripple={false}
                id={`services-item-${index}`}
                containerProps={{ className: "p-0" }}
                className="hover:before:content-none"
                onChange={() => onServicesChange(item)}
                checked={servicesSelections.includes(item)}
              />
              {item}
            </label>
          </MenuItem>
        ))}
        <hr className="my-3" />

        <MenuItem className="p-0" key="select-all">
          <label
            htmlFor={`services-item-00`}
            className="flex cursor-pointer items-center gap-2 p-2"
          >
            <Checkbox
              ripple={false}
              id={`services-item-00`}
              containerProps={{ className: "p-0" }}
              className="hover:before:content-none"
              onChange={() => onSelectAll()}
              checked={!selectAll}
            />
            {"Select All"}
          </label>
        </MenuItem>

        <MenuItem className="p-0" key="update">
          <Button onClick={() => onUpdate()}>Update</Button>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ServicesListMenu;
