import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Chip,
} from "@material-tailwind/react";
import StatusListMenu from "../Menus/StatusListMenu";

import { statusList } from "../../config";

const StatusFilterControl = ({ status, setStatus }) => {
  const renderStatusSummary = () => {
    if (status.length === 0) {
      return <Chip color="gray" size="sm" value="none selected" />;
    } else if (status.length === 1) {
      return <Chip color="gray" size="sm" value={status[0]} />;
    } else if (status.length === statusList.length) {
      return <Chip color="gray" size="sm" value="all selected" />;
    } else if (status.length > 1) {
      return (
        <Chip color="gray" size="sm" value={`${status.length} selected`} />
      );
    }
  };

  const renderStatusTooltipText = () => {
    return status.join("\n");
  };

  return (
    <Card className="w-full max-w-[30rem]">
      <CardBody>
        <div className="flex justify-between items-center">
          <Typography variant="h5" color="blue-gray" className="">
            Status
          </Typography>
          <StatusListMenu status={status} setStatus={setStatus} />
        </div>

        <div className="flex ">
          <Tooltip content={renderStatusTooltipText()} placement="top">
            {renderStatusSummary()}
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
};

export default StatusFilterControl;
