// src/api.js
import axios from "axios";

import fakeAxios from "../mocks/mockAxios"

const apiAxios = axios.create({
  baseURL: process.env.REACT_APP_HUB_URL, // Replace with your API URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to attach the bearer token to every request
apiAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const shouldUseMock = process.env.REACT_APP_USE_MOCK_API === "true";
const api = shouldUseMock ? fakeAxios :apiAxios;

export default api;
