"use client";

import { useState } from "react";
import TaskService from "../services/Task";

const useTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [taskError, setTaskError] = useState({});

  const fetchTasks = async (options) => {
    try {
      setTasksLoading(true);

      // are we looking for a single task?
      if (options.id) {
        const data = await TaskService.getTask(options.id);

        setTasksLoading(false);
        setTasks([data]);
      } else {
        const data = await TaskService.getTasks(
          options.userId,
          options.startDate,
          options.startDate
        );
        setTasksLoading(false);

        if (data) {
          setTasks(data);
        }
      }
    } catch (error) {
      console.error("Error fetching Takss:", error);
      throw error;
    }
  };

  const createTask = async (options) => {
    try {
      const id = await TaskService.createTask(
        options.title,
        options.authorId,
        options.startDate,
        options.endDate,
        options.projectId,
        options.notes
      );

      const newTask = {
        id: id,
        ...options,
      };
      const allTasks = [...tasks, newTask];

      setTasks(allTasks);
      return id;
    } catch (error) {
      setTaskError(error);
      throw error;
    }
  };

  return [tasks, tasksLoading, fetchTasks, createTask, taskError];
};

export default useTasks;
