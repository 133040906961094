import {
  Select,
  Option,
} from "@material-tailwind/react";

import { servicesList } from "../../config"

const ServicesSelectControl = ({selection, onSelection, hasError}) => {

  const onChange = (val) => {

    const data = {
      target: {
        id: "service",
        value: val
      }
    }
    onSelection(data)
  }

  return (
    <Select 
      id="service"
      error={hasError}
      size="lg" 
      label="What service are they interested in?"
      value={selection}
      onChange={(val) => onChange(val)}
    >
      {servicesList.map((service, index) => {
        return (
          <Option key={index} value={service}>{service}</Option>
        )
      })}
    </Select>
  )
}

export default ServicesSelectControl