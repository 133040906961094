import { useState } from "react";
import DataTable from "./DataTable";

import { formatDistance } from "date-fns";
import QuickNoteDialog from "../Dialogs/QuickNoteDialog";

const columns = [
  {
    accessorKey: "client",
    header: "Company",
    //Render images in a cell render
    Cell: ({ cell }) => cell.getValue().title,
  },
  {
    accessorKey: "title",
    header: "Project",
    //Render images in a cell render
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    accessorKey: "source",
    header: "Source",
    //Render images in a cell render
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    accessorKey: "lastStatus",
    header: "Status",
    //Render images in a cell render
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    accessorKey: "serviceType",
    header: "Service",
    //Render images in a cell render
    Cell: ({ cell }) => cell.getValue(),
  },
  {
    accessorKey: "estimated_value",
    header: "Value",
    //Format a number in a cell render
    Cell: ({ cell }) => <span>£{cell.getValue().toLocaleString()}</span>,
  },
  {
    accessorKey: "created",
    header: "Created",
    //Format a number in a cell render
    Cell: ({ cell }) => (
      <span>
        {formatDistance(new Date(cell.getValue()), new Date(), {
          addSuffix: true,
        })}
      </span>
    ),
  },
  {
    accessorKey: "modified",
    header: "Updated",
    //Format a number in a cell render
    Cell: ({ cell }) => (
      <span>
        {formatDistance(new Date(cell.getValue()), new Date(), {
          addSuffix: true,
        })}
      </span>
    ),
  },
];

const ProspectsDataTable = ({ data, onNewItem, onOpenItem, onEditItem }) => {
  const [quickNoteOpen, setQuickNoteOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const menuActions = [
    {
      label: "Quick Note",
      onClick: (row) => {
        onQuickNote(row);
      },
    },
    {
      label: "Open",
      onClick: (row) => {
        onOpenItem(row.original.id, row.original);
      },
    },
    {
      label: "Edit",
      onClick: (row) => {
        onEditItem(row.original.id, row.original);
      },
    },
    {
      label: "Delete",
      onClick: (row) => {
        console.log("Delete clicked on row: ", row.original.id);
      },
    },
  ];

  const onRenderDetailPanel = (row) => {
    return (
      <div className="flex flex-col gap-4">
        <div>
          {row.original.actionTimeline ? (
            <>
              <h2>Updates: {row.original.actionTimeline.length}</h2>
              <ul>
                {row.original.actionTimeline.map((action, index) => (
                  <li key={index}>
                    <strong>{action.date}</strong> - {action.action.label}:{" "}
                    {action.notes}
                  </li>
                ))}
              </ul>
            </>
          ) : (
            <h2>No updates yet</h2>
          )}
        </div>
      </div>
    );
  };

  const onQuickNote = (row) => {
    setSelectedRow(row.original);
    setQuickNoteOpen(true);
  };

  const onContextMenu = (event, row) => {
    console.log("Right clicked on row: ", row);
  };
  return (
    <>
      <QuickNoteDialog
        open={quickNoteOpen}
        setOpen={setQuickNoteOpen}
        prospect={selectedRow}
      />
      <DataTable
        columns={columns}
        data={data}
        newItemName="Prospect"
        onNewItem={onNewItem}
        onOpenItem={onOpenItem}
        onRightClickItem={onContextMenu}
        menuActions={menuActions}
        onRenderDetailPanel={onRenderDetailPanel}
      />
    </>
  );
};

export default ProspectsDataTable;
