const servicesList = [
  "Existing website updates",
  "WordPress updates",
  "Domain/Hosting support",
  "Take over website",
  "New website",
  "TimeBank",
  "SEO",
  "Social Media",
  "Videography",
  "Consultancy",
  "Graphic Design",
  "Illustration",
  "Interactive Media",
  "Training",
  "App",
  "Other",
];

export default servicesList;
