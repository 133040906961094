import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useSession } from "../context/SessionContext";

const Login = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  /*
  const {
    activeTeamMember,
    setActiveTeamMember,
    setSelectedDay,
    setSelectedTime,
  } = useSession();
*/
  
  const { userName } = useParams();

  useEffect(() => {
    /*
    const getTeamMembers = async () => {
      try {
        const data = await UserService.getActiveUsers();
        setTeamMembers(data);

        if (userName) {
          const user = data.find(
            (user) =>
              user.data.display_name.split(" ")[0].toLowerCase() ==
              userName.toLowerCase()
          );
          setActiveTeamMember(user);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getTeamMembers();
    */

  }, []);

  return (
    <main className="flex items-center justify-center h-screen min-h-[450px]">
      <div className="container flex flex-col sm:flex-row border h-5/6 min-h-[400px] rounded-2xl drop-shadow-xl">
        <div className="flex flex-col p-4 bg-white border rounded-l-lg sm:w-1/3 min-w-min">
          <div className="flex items-center justify-center mb-2">
            <img
              src="https://hub.fl1digital.com/wp-content/uploads/2018/04/fl1-logo.png"
              className="w-[150px]"
            />
          </div>
          <hr className="" border="true" />
          Login
          
        </div>
        <div className="p-4 text-white bg-white border rounded-r-lg md:w-3/4">
         
        </div>
      </div>
    </main>
  );
};

export default Login;
