'use client'

import { useState } from 'react';
import ClientService from '../services/Client';

const useClients = () => {
    const [clients, setClients] = useState([]);
    const [clientsLoading, setClientsLoading] = useState(true);

    const fetchClients = async (options) => {

		try {
            setClientsLoading(true);
            
			const data = await ClientService.getClients();
            setClientsLoading(false);

            if (data) {
                setClients(data);
            }
        } catch (error) {
            const errors = [{ message: error.message }];
            return { errors, rows: 0 };
        }
    }

    const createClient = async (options) => {

        const result = {};
		try {
            
            const errors = await ClientService.validateNewClient(options);
            console.log('Errors:', errors);
            if (errors.length > 0) {
                result.errors = errors;
                result.id = 0;
                return { errors, id: 0 };
            }
            
            const id = await ClientService.createClient(
                options.company,
                options.authorId,
                options.contact,
                options.email,
                options.phone
            )

            const newClient = {
                id: id,
                ...options};
                    
            const allClients = [
                ...clients,
                newClient
            ]

            // Order all the clients by name
            allClients.sort((a, b) => a.company.localeCompare(b.company));
            
            setClients(allClients)
            return { errors: [], id: id };
                
        } catch (error) {
            const errors = [{ message: error.message }];
            return { errors, id: 0 };
        }    
    }

    return [ clients, clientsLoading, fetchClients, createClient ];
}

export default useClients;