import axios from "axios";

class MailChimpService {
  static async subscribeToMailingList(name, email) {
    try {
      const listId = process.env.REACT_APP_MAILCHIMP_LIST_ID;
      const url = `${process.env.REACT_APP_MAILCHIMP_API}/lists/${listId}/members`;
      const token = process.env.REACT_APP_MAILCHIMP_API_KEY;

      let data = JSON.stringify({
        email_address: email,
        status: "subscribed",
        merge_fields: {
          FNAME: name,
        },
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      const res = await axios.request(config);

      console.log(res.data);
      return res.data.id;
    } catch (error) {
      console.error(error);
    }
  }
}

export default MailChimpService;
