const getMonthStartEndDates = (date) => {
  const start = 1;

  const numDays = (y, m) => new Date(y, m, 0).getDate();
  const end = numDays(date.getFullYear(), date.getMonth() + 1);

  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const first = dateFormatWordPress(startDate);
  const last = dateFormatWordPress(endDate);

  return {
    first,
    last,
  };
};

const dateFormatWordPress = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // get hours and minutes
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const getWeekNumber = (date) => {
  // Copy date so don't modify original
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  // Get first day of year
  var yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  // Calculate full weeks to nearest Thursday
  var weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  // Return array of year and week number
  return weekNo;
};

const getNextWorkingDay = (setTime) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1); // Get tomorrow's date

  // Check if tomorrow is Saturday (6) or Sunday (0)
  if (tomorrow.getDay() === 6) {
    // Saturday
    tomorrow.setDate(tomorrow.getDate() + 2); // Move to Monday
  } else if (tomorrow.getDay() === 0) {
    // Sunday
    tomorrow.setDate(tomorrow.getDate() + 1); // Move to Monday
  }

  if (setTime) {
    tomorrow.setHours(setTime.hours, setTime.minutes, 0, 0);
  }

  return tomorrow;
};

// Example usage:
const nextWorkingDay = getNextWorkingDay();
console.log(nextWorkingDay);

export {
  getMonthStartEndDates,
  dateFormatWordPress,
  getWeekNumber,
  getNextWorkingDay,
};
