import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Layout from "../components/Layout";
import DateRangeFilterControl from "../components/Controls/DateRangeFilterControl";
import ServicesFilterControl from "../components/Controls/ServicesFilterControl";
import StatusFilterControl from "../components/Controls/StatusFilterControl";

import ClientsDataTable from "../components/Tables/ClientsDataTable";
import useClients from "../hooks/useClients";
import useLocalStorage from "../hooks/useLocalStorage";

const dateRangeDefault = {
  startDate: new Date(),
  endDate: new Date(),
};
dateRangeDefault.startDate.setMonth(dateRangeDefault.startDate.getMonth() - 6);

const Clients = () => {
  const [statusFilter, setStatusFilter] = useLocalStorage("status", []);
  const [servicesFiler, setServicesFilter] = useLocalStorage("services", []);
  const [dateRangeFilter, setDateRangeFilter] = useLocalStorage(
    "filter_dates",
    dateRangeDefault
  );

  const [clients, , fetchClients] = useClients();

  const navigate = useNavigate();

  const [filteredClients, setFilteredClients] = useState([]);

  const applyFilters = (clients) => {
    const filteredClients = [];
    clients.forEach((client) => {
      const updatedClient = client;
      /*

      if (statusFilter.includes(prospect.prospect_status.name)) {
        updatedProspect.lastStatus = prospect.prospect_status.name;
        filteredProspects.push(updatedProspect);
      }
      */
    });

    setFilteredClients(clients);
  };

  useEffect(() => {
    fetchClients();
  }, []);

  useEffect(() => {
    applyFilters(clients);
  }, [clients]);

  const onNewClient = () => {
    navigate("https://hub.fl1digital.com/clients/add-new/");
  };

  const onOpenClient = (id, prospect) => {
    window.location.href = prospect.permalink;
  };
  const onEditClient = (id, prospect) => {
    window.location.href = `${prospect.permalink}edit`;
  };
  return (
    <Layout>
      <div className="flex flex-col gap-4 items-center justify-center lg:flex-row mb-4">
        <ServicesFilterControl
          services={servicesFiler}
          setServices={setServicesFilter}
        />
        <StatusFilterControl
          status={statusFilter}
          setStatus={setStatusFilter}
        />
        <DateRangeFilterControl
          dateRange={dateRangeFilter}
          setDateRange={setDateRangeFilter}
        />
      </div>
      {filteredClients ? (
        <>
          <ClientsDataTable
            data={filteredClients}
            onNewItem={onNewClient}
            onOpenItem={onOpenClient}
            onEditItem={onEditClient}
          />
        </>
      ) : (
        <div>Loading...</div>
      )}
    </Layout>
  );
};

export default Clients;
