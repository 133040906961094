import {
  Card,
  CardBody,
  Typography,
  Tooltip,
  Chip,
} from "@material-tailwind/react";

import { format } from "date-fns";

import DateRangeDialog from "../Dialogs/DateRangeDialog";

const DateRangeFilterControl = ({ dateRange, setDateRange }) => {
  const renderDateRangeSummary = () => {
    const dateRangeMonthToDate = {
      startDate: new Date(),
      endDate: new Date(),
    };

    dateRangeMonthToDate.startDate.setDate(1);

    const from = dateRange.startDate
      ? format(dateRange.startDate, "MMM d")
      : format(dateRangeMonthToDate.startDate, "MMM d");
    const to = dateRange.endDate
      ? format(dateRange.endDate, "MMM d")
      : format(dateRangeMonthToDate.endDate, "MMM d");

    if (dateRange.startDate === dateRange.endDate) {
      return <Chip color="gray" size="sm" value={from} />;
    } else if (dateRange.startDate !== dateRange.endDate) {
      return <Chip color="gray" size="sm" value={`${from} - ${to}`} />;
    }
  };

  const renderDateRangeTooltipText = () => {
    const text =
      "Show Sales for the period " +
      format(dateRange.endDate, "MMM d") +
      " - " +
      format(dateRange.startDate, "MMM d");
    return <Typography>{text}</Typography>;
  };

  return (
    <Card className="w-full max-w-[20rem]">
      <CardBody>
        <div className="flex justify-between items-center">
          <Typography variant="h5" color="blue-gray" className="">
            Period
          </Typography>
          <DateRangeDialog dateRange={dateRange} setDateRange={setDateRange} />
        </div>

        <div className="flex ">
          <Tooltip content={renderDateRangeTooltipText()} placement="top">
            {renderDateRangeSummary()}
          </Tooltip>
        </div>
      </CardBody>
    </Card>
  );
};

export default DateRangeFilterControl;
