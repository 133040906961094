import { useState } from "react";
import {
  Typography,
  Textarea,
  Select,
  Option,
  Button,
} from "@material-tailwind/react";

import DateInputPicker from "../Pickers/DateInputPicker";

const NextStepSelectionControl = ({
  selectedNextStep,
  setSelectedNextStep,
  onCreateProspect,
  isSaving,
}) => {
  const [followUpDate, setFollowUpDate] = useState(
    selectedNextStep.followUpStartTime
  );

  const onFieldChange = (e) => {
    setSelectedNextStep({
      ...selectedNextStep,
      [e.target.id]: e.target.value,
    });
  };

  const setFollowUpType = (value) => {
    setSelectedNextStep({
      ...selectedNextStep,
      followUpType: value,
    });
  };

  const onSetDate = (date) => {
    setFollowUpDate(date);

    // create a date object from the date string for 10am of that day
    // get the date parts
    const startDate = new Date(date);
    startDate.setHours(10, 0, 0, 0);

    const endDate = new Date(date);
    endDate.setHours(11, 0, 0, 0);

    setSelectedNextStep({
      ...selectedNextStep,
      followUpStartTime: startDate,
      followUpEndTime: endDate,
    });
  };

  return (
    <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
      <div className="mb-1 flex flex-col gap-6">
        <Typography variant="h6" color="blue-gray" className="-mb-3">
          Follow Update Date
        </Typography>
        <DateInputPicker
          id="followUpDate"
          date={followUpDate}
          setDate={onSetDate}
        />

        <Typography variant="h6" color="blue-gray" className="-mb-3">
          Follow Up action
        </Typography>
        <Select
          id="followUpType"
          size="lg"
          label="What needs to happen next?"
          value={selectedNextStep.followUpType}
          onChange={(val) => setFollowUpType(val)}
        >
          <Option value="Google Meet">Google Meet</Option>
          <Option value="Investigation">Investigation</Option>
          <Option value="Quote">Quick Quote</Option>
          <Option value="Proposal">Full Proposal</Option>
          <Option value="Phone">Phone Call</Option>
          <Option value="In-person Meeting">Meet in person</Option>
        </Select>

        <Typography variant="h6" color="blue-gray" className="-mb-3">
          Follow up Notes
        </Typography>
        <Textarea
          id="followUpNotes"
          variant="outlined"
          label="Follow up task notes"
          rows={14}
          onChange={onFieldChange}
          value={selectedNextStep.followUpNotes}
        />
        <Button
          color="red"
          size="lg"
          className="w-full"
          loading={isSaving}
          onClick={() => onCreateProspect()}
        >
          Create Prospect
        </Button>
      </div>
    </form>
  );
};

export default NextStepSelectionControl;
